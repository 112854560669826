import React from 'react';
import {Link} from 'gatsby';

const Footer = ({lang}) => (
  <footer className="footer">
    <div className="container">
      <div className="has-text-centered">
        <h5>Heal to Thrive</h5>
        { lang === 'pl' ?
          <Link className="is-size-7 has-text-weight-light" to="/pl/polityka-prywatnosci">Polityka Prywatności</Link>
          :
          <Link className="is-size-7 has-text-weight-light" to="/privacy-policy">Privacy Notice and GDPR</Link>
        }
        <p className="is-size-7 has-text-weight-light">&copy; Copyright Heal to Thrive {new Date().getFullYear()}. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;